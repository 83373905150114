import axios from "axios";
import {
  url,
  dispatchLoading,
  dispatchToast,
  dispatchError,
  catchHandler,
  elseHandler,
} from "../../utils";
import {
  FetchBookingListWithCategoryAndCarId,
  FetchBookingUpdate,
} from "../../Admin/Redux/Booking";
import { BookingData } from "../../Admin/Redux/Dashboard";

// --- Actions --- //
const bookingUrl = url + "booking";

export const FetchBookingListCount = () => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  let noDiskCache = { noDiskCache: 1, timeStamp: `${new Date().getTime()}` };
  axios
    .get(bookingUrl + "/list/count", {
      params: noDiskCache,
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_LIST_COUNT", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};

export const FetchBookingList = (listType, pageId) => async (dispatch) => {
  dispatchLoading(dispatch, "bookingList", true);
  axios
    .get(bookingUrl + "/list", {
      params: { rideStatus: listType, pageId: pageId ?? 0, itemPerPage: 5 },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_LIST_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "bookingList", false);
    })
    .catch(catchHandler(dispatch, "bookingList"));
};
export const FetchBookingCancelReason = () => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  let noDiskCache = { noDiskCache: 1, timeStamp: `${new Date().getTime()}` };
  axios
    .get(bookingUrl + "/reason", { params: noDiskCache, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "BOOKING_CANCELREASON_SUCCESS",
          payload: result?.data?.data,
        });
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);

      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const FetchBookingReload = (bookingId, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .get(bookingUrl, { params: { bookingId }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_LOAD_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
      } else {
        elseHandler(dispatch, "booking", result?.data);
        navigate("/");
      }
      dispatchLoading(dispatch, "booking", false);
    })
    .catch((err) => {
      dispatchToast(dispatch, "error", "You refreshed the page!");
      navigate("/booking");
    });
};
export const FetchBookingReloadUnauthenticated =
  (bookingId, email, type, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    let params = {};
    if (email !== undefined || email !== "") params["email"] = email;
    if (type !== undefined) params["type"] = type;
    if (bookingId !== undefined) params["bookingId"] = bookingId;
    axios
      .get(bookingUrl + "/un-auth", { params: params, withCredentials: true })
      .then((result) => {
        if (result?.data?.success) {
          dispatch({
            type: "BOOKING_LOAD_SUCCESS",
            payload: result?.data?.data,
          });
          dispatchError(dispatch, "booking", undefined);
          if (type !== "reload")
            navigate("/booking/detail/" + result?.data?.data._id);
        } else {
          elseHandler(dispatch, "booking", result?.data);
        }
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const CheckCarAvailability =
  (carId, fromDate, toDate, successCB, errorCB) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .get(bookingUrl + "/check-availability", {
        params: { carId, fromDate, toDate },
        withCredentials: true,
      })
      .then((result) => {
        if (result?.data?.success) successCB();
        else {
          dispatch({ type: "BOOKING_AVAILABLE_DATE", payload: result?.data });
          errorCB();
        }
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const InitBooking =
  (carId, location, dateTime, dateTime_formatted, navigate) =>
  async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .post(
        bookingUrl + "/init",
        { carId, location, dateTime, dateTime_formatted },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          dispatch({
            type: "BOOKING_INIT_SUCCESS",
            payload: result?.data?.data,
          });
          dispatchError(dispatch, "booking", undefined);
          navigate("/booking/" + result?.data?.data?._id);
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const BookingFailure = (bookingId, navigate) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .get(bookingUrl + "/fail", { params: { bookingId }, withCredentials: true })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "BOOKING_FAIL_SUCCESS", payload: result?.data?.data });
        dispatchError(dispatch, "booking", undefined);
        navigate("/");
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const RentalOption =
  (bookingId, driverDetail = {}, rentalOptions) =>
  async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .post(
        bookingUrl + "/rental-opt",
        {
          bookingId,
          driverDetail,
          rentalOptions,
        },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          dispatch({
            type: "BOOKING_RENTALOPS_SUCCESS",
            payload: result?.data?.data,
          });
          dispatchError(dispatch, "booking", undefined);
        } else dispatchError(dispatch, "booking", result?.data?.error);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const BookingConfirmMail = (bookingId, mail) => async (dispatch) => {
  dispatchLoading(dispatch, "booking", true);
  axios
    .post(
      bookingUrl + "/confirm-mail",
      {
        bookingId,
        mail,
      },
      { withCredentials: true }
    )
    .then((result) => {
      if (result?.data?.success) {
        dispatchError(dispatch, "booking", undefined);
      } else dispatchError(dispatch, "booking", result?.data?.error);
      dispatchLoading(dispatch, "booking", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const DriverDetails =
  (bookingId, driverDetails, nextStepHandler) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .post(
        bookingUrl + "/driver-detail",
        { bookingId, driverDetails },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          dispatch({
            type: "BOOKING_DRIVERDETAIL_SUCCESS",
            payload: result?.data?.data,
          });
          dispatchError(dispatch, "booking", undefined);
          nextStepHandler();
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const EditBookingAPI =
  (
    bookingId,
    data,
    navigate,
    role,
    rideApproved,
    requireApproval,
    advanceBooking
  ) =>
  async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    dispatch({
      type: "BOOKING_DRIVERDETAIL_SUCCESS",
      payload: data?.driverDetail,
    });
    axios
      .put(
        bookingUrl + "/edit",
        {
          bookingId,
          updates: data,
          role: role,
        },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          dispatch({
            type: "BOOKING_RENTALOPS_SUCCESS",
            payload: result?.data?.data,
          });
          dispatchError(dispatch, "booking", undefined);
          if (role !== "customer") {
            if (rideApproved === null) {
              if (requireApproval === false && advanceBooking === false) {
                navigate(`/${role}/booking/#upcoming`, { replace: true });
              } else {
                navigate(`/${role}/booking/#request`, { replace: true });
              }
            } else {
              navigate(`/${role}/booking/#upcoming`, { replace: true });
            }
          } else {
            navigate(`/booking/detail/${bookingId}`, { replace: true });
          }
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const CancelBookingAPI =
  (bookingId, reason, comment, isLoggedIn) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .put(
        bookingUrl + "/cancel",
        {
          bookingId,
          reason,
          comment,
        },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          if (isLoggedIn) {
            dispatch(FetchBookingList());
            dispatch(FetchBookingListCount());
          }
          dispatchToast(dispatch, "success", result?.data?.msg);
          // dispatchError(dispatch, "booking", undefined);
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };

export const CancelBookingNoRefundAPI =
  (bookingId, reason, comment, navigate, navigateURL) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .put(
        bookingUrl + "/cancel-no-refund",
        {
          bookingId,
          reason,
          comment,
        },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          // dispatch(FetchBookingList());
          dispatch(FetchBookingListWithCategoryAndCarId("cancelled", false));
          dispatch(BookingData());
          dispatchToast(dispatch, "success", result?.data?.msg);
          navigate(navigateURL);
          // dispatchError(dispatch, "booking", undefined);
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };

export const PromocodeApply = (bookingId, email, code) => async (dispatch) => {
  dispatchLoading(dispatch, "promocode", true);
  axios
    .post(
      bookingUrl + "/promocode",
      { bookingId, email, code },
      { withCredentials: true }
    )
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "BOOKING_PROMOAPPLY_SUCCESS",
          payload: result?.data?.data,
        });
        dispatchToast(dispatch, "success", result?.data?.msg);
        dispatchError(dispatch, "booking", undefined);
      } else {
        elseHandler(dispatch, "booking", result?.data);
        dispatchToast(dispatch, "error", result?.data?.msg);
      }
      dispatchLoading(dispatch, "promocode", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const PromocodeRemove = (bookingId) => async (dispatch) => {
  dispatchLoading(dispatch, "promocode", true);
  axios
    .delete(bookingUrl + "/promocode", {
      data: { bookingId },
      withCredentials: true,
    })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({
          type: "BOOKING_PROMOREMOVE_SUCCESS",
          payload: result?.data?.data,
        });
        dispatchToast(dispatch, "success", result?.data?.msg);
        dispatchError(dispatch, "booking", undefined);
      } else elseHandler(dispatch, "booking", result?.data);
      dispatchLoading(dispatch, "promocode", false);
    })
    .catch(catchHandler(dispatch, "booking"));
};
export const DeleteDocument =
  (photoId, bookingId, name, successCB) => async (dispatch) => {
    axios
      .delete(bookingUrl + "/confirm/upload", {
        data: { photoId, bookingId, name },
        withCredentials: true,
      })
      .then((result) => {
        if (result?.data?.success) {
          successCB();
          dispatchError(dispatch, "booking", undefined);
        } else elseHandler(dispatch, "booking", result?.data);
      })
      .catch(catchHandler(dispatch, "booking"));
  };

export const DeleteExtraDocument =
  (photoId, bookingId, driverId, name, successCB) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .delete(bookingUrl + "/confirm/upload/extra", {
        data: { photoId, bookingId, driverId, name },
        withCredentials: true,
      })
      .then((result) => {
        if (result?.data?.success) {
          successCB();
          dispatchError(dispatch, "booking", undefined);
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };

export const proceedPayment =
  (bookingId, redirectURL, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "booking", true);
    axios
      .post(
        bookingUrl + "/payment",
        { bookingId, redirectURL },
        { withCredentials: true }
      )
      .then((result) => {
        if (result?.data?.success) {
          window.location.href = result?.data?.data?.url;
        } else elseHandler(dispatch, "booking", result?.data);
        dispatchLoading(dispatch, "booking", false);
      })
      .catch(catchHandler(dispatch, "booking"));
  };
export const FetchUnauthCarList = () => async (dispatch) => {
  dispatchLoading(dispatch, "car", true);
  axios
    .get(url + "car/dropdown/list", { withCredentials: false })
    .then((result) => {
      if (result?.data?.success) {
        dispatch({ type: "CAR_DROPDOWN_LIST", payload: result?.data?.data });
        dispatchError(dispatch, "car", undefined);
      }
      dispatchLoading(dispatch, "car", false);
    })
    .catch(catchHandler(dispatch, "car"));
};
