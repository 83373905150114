import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorLogo from "./Assets/Images/error.svg";
import moment from "moment";

export const alias = process.env.REACT_APP_ALIAS;
export const url = process.env.REACT_APP_API_URL;
export const bucketUrl = process.env.REACT_APP_BUCKET_URL;
export const baseUrl = process.env.REACT_APP_BASE_URL;

// bucketUrl URLS
export const carImageUrl = `${bucketUrl}/car`;
export const profileImageUrl = `${bucketUrl}/profile`;
export const documentImageUrl = `${bucketUrl}/document`;
export const RideImageUrl = `${bucketUrl}/ride`;
export const blogImageUrl = bucketUrl;
export const carTypeImageUrl = `${bucketUrl}/type`;
export const resumeImageUrl = `${bucketUrl}/resume`;
export const helpImageUrl = bucketUrl;

export const redirectURLSripe = (loc) =>
  [loc.origin, loc.pathname.split("/")[1], loc.pathname.split("/")[2]].join(
    "/"
  ); // Dev
// export const redirectURLSripe = (loc) =>
//   [loc.origin, loc.pathname.split("/")[1]].join("/"); // Live

// Redirect link for the apple login
export const appleRedirectLink = "https://www.dionyrental.com/api/auth/apple";

// Date Time Format
export const momentDateTimeFormat = "DD/MM/YY HH:mm";
export const dayjsDateFormat = "DD/MM/YY";
export const hourMinuteFormat = "HH:mm";
export const dobFormat = "DD/MM/YYYY";

export const momentDDMMYY_HHMM = (data) =>
  moment(data).format(momentDateTimeFormat);

// util function
export const dispatchLoading = (dispatch, scope, status) =>
  dispatch({ type: "SET_LOADING", payload: { scope, status } });
export const dispatchToast = (dispatch, scope, status) =>
  dispatch({ type: "SET_TOAST", payload: { scope, status } });
export const dispatchError = (dispatch, scope, status) =>
  dispatch({ type: "SET_ERROR", payload: { scope, status } });
export const catchHandler = (dispatch, scope) => (err) => {
  if (err.code === "ERR_NETWORK")
    dispatchToast(dispatch, "error", "Unable to connect to server");
  else if (err?.response?.status !== 401)
    dispatchError(dispatch, scope, err?.response?.data?.error);
  else dispatchToast(dispatch, "error", err?.response?.data?.msg);
  dispatchLoading(dispatch, scope, false);
};
export const elseHandler = (dispatch, scope, data) => {
  dispatchToast(dispatch, "error", data?.msg);
  dispatchError(dispatch, scope, data?.error);
};
export const ProtectedRoute = ({ roleName, children }) => {
  const { isLoggedIn, role } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (isLoggedIn === true && role === roleName)
    return <React.Fragment>{children}</React.Fragment>;
  else navigate("/auth/" + roleName, { replace: true });
};

export const SiteLoader = ({ text }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  if (pathname === "/") {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        width: "calc(100% - 1rem)",
      }}
    >
      <p className="Loader">
        <div className="d-block">
          <div className="spinner-border" role="status"></div>
        </div>
      </p>
      <p className="ff_md" style={{ fontSize: "20px", lineHeight: "1.1" }}>
        {text !== undefined ? text : t("labels.pleaseWait")}
      </p>
    </div>
  );
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  // console.log(error, resetErrorBoundary);
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);
  const goToBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (pathname !== originalPathname.current) resetErrorBoundary();
  }, [pathname, resetErrorBoundary]);

  return (
    <div
      className="text-center w-100 d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <div className="px-3 mb-5 mt-4 header-logo">
          <img
            src={ErrorLogo}
            alt="header logo"
            className="img-fluid"
            onClick={(e) => navigate("/")}
          />
        </div>
        <h5 className="ff_rg mb-1 f18">{t("error.somethingWrong")}</h5>
        <p className="ff_rg f14">
          {t("error.thereWasProblem")}
          <span className="d-block ff_rg f14 mx-3">
            {t("error.pleaseRefresh")}
          </span>
        </p>
        <p className="ff_rg f14 mt-3 text-secondary">
          {t("error.somethingNot")} ?{" "}
          <span className="ff_md f14 text-dark">
            {t("error.contact")} :{" "}
            <Link
              className="ff_md f14 text-dark"
              to="mailto: support@dionyrental.com"
            >
              support@dionyrental.com
            </Link>{" "}
          </span>
        </p>
        <button
          className="px-3 h50 c-primary border-n ff_bd f14 textWhite minWidth150 txt-white mt-5"
          onClick={() => goToBack()}
        >
          {t("labels.goBack")}
        </button>
      </div>
    </div>
  );
};
export const getObjectDifference = (originalObj, updatedObj) => {
  var diff = {};

  function compareObjects(original, updated, path) {
    for (let key in updated) {
      if (!original.hasOwnProperty(key)) {
        // New key found in updatedObj
        diff[path + "." + key] = {
          oldValue: undefined,
          newValue: updated[key],
        };
      } else if (
        typeof updated[key] === "object" &&
        typeof original[key] === "object"
      ) {
        // Nested objects - recursively compare
        compareObjects(original[key], updated[key], path + "." + key);
      } else if (original[key] !== updated[key]) {
        // Value changed for the key
        diff[path + "." + key] = {
          oldValue: original[key],
          newValue: updated[key],
        };
      }
    }

    for (let key in original) {
      if (!updated.hasOwnProperty(key)) {
        // Key removed in updatedObj
        diff[path + "." + key] = {
          oldValue: original[key],
          newValue: undefined,
        };
      }
    }
  }

  compareObjects(originalObj, updatedObj, "");

  return diff;
};
